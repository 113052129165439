<template>
  <v-sheet elevation="5" class="my-10">
    <v-row>
      <v-col class="d-lg-block d-none"></v-col>
      <v-col lg="6" md="12" sm="12" class="">
        <v-card elevation="8">
          <v-card-text>
            <h4 class="my-lg-2 my-3">CONTACT US</h4>
            <v-form @submit.prevent="contactForm" class="px-5">
              <v-text-field
                type="text"
                clearable
                density="comfortable"
                label="Name"
                name="name"
                variant="outlined"
                v-model.trim="contactUs.sender"
                required
                hint="Enter your full name"
              />

              <v-text-field
                type="email"
                label="Email Address"
                name="email"
                clearable
                variant="outlined"
                density="comfortable"
                v-model.trim="contactUs.email"
                required
                hint="Please Enter Your Email"
              />

              <v-text-field
                label="Contact"
                type="tel"
                required
                clearable
                variant="outlined"
                v-model.trim="contactUs.contact"
                name="contact"
                density="comfortable"
                hint="Enter your phone number"
              />

              <div class="">
                <v-textarea
                  v-model.trim="contactUs.message"
                  variant="outlined"
                  label="Type Your Message"
                  required
                  clearable
                ></v-textarea>

                <div class="d-lg-flex justify-content-end mt-0">
                  <small class="">
                    Message Max Word Limit: {{ contactUs.message.length }}/250
                  </small>
                  <small class="text-danger" v-if="messageError"
                    >Check your message length.</small
                  >
                </div>
              </div>

              <div class="d-flex justify-content-center">
                <div
                  class="g-recaptcha"
                  data-sitekey="6LfF3-4pAAAAAO88POyzMjDEAOjovG4py-UMc0Wt"
                ></div>
              </div>

              <div class="mt-2">
                <v-btn block color="green-darken-4" size="large"> Send </v-btn>
                <div class="alert">
                  <p>{{ messageStatus }}</p>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>

  <!-- <div class="client-banner"></div> -->
  <div v-if="sentFollowUp" class="py-2 services">
    <p class="py-lg-3 text-body-1 alert alert-success">
      Thank You for reaching out to us. We have received your message and will
      get back to you shortly. Please do well to call .
      <a href="tel:0246011004">0246011004</a> or
      <a href="tel:0243934353">0243934353</a> if it is urgent. Thank you.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactUs: {
        sender: "",
        email: "",
        contact: "",
        type: "",
        message: "".substring(0, 250),
        date: new Date(),
      },
      sentFollowUp: false,
      MessageSent: true,
      disableBtn: false,
      messageError: false,
      wheel: false,
      positive: true,
      messageStatus: null,
    };
  },

  methods: {
    async contactForm() {
      // console.log("Contact Form", this.contactUs);
      if (this.contactUs.message.length > 250) {
        this.messageError = true;
      } else {
        try {
          this.messageError = false;
          this.wheel = true;
          const message = this.contactUs;
          const MessageRef = await addDoc(collection(db, "Messages"), message);
          console.log("Document written with ID: ", MessageRef.id);
          this.sentFollowUp = true;
          this.contactUs.sender = "";
          this.contactUs.email = "";
          this.contactUs.contact = "";
          this.contactUs.type = "";
          this.contactUs.message = "";
        } catch {
          this.wheel = false;
          this.positive = false;
          console.error("Error Sending Message", error);
        }
      }
    },
  },

  inheritAttrs: false,
};
</script>

<style scoped>
/* .banner {
  height: 10rem;
} */
</style>
